import { Box, Collapse, Divider, Drawer, FormControlLabel, FormGroup, IconButton, InputAdornment, List, ListItem, Checkbox, ListItemText, TextField } from "@mui/material"
import React, { useState, useEffect } from "react"
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import lang from "../../asset/Language.json"
import { lightGreen } from "@mui/material/colors";
import KhuVucMasterCheckList from "./KhuvucChecklist";
import MonthYearPicker from "../MonthYearPicker";
import LotrinhStatuses from "./LotrinhStatuses";

export default function SearchFilter(props) {
  const { cusId, disabledSearchText, disabledSearchLotrinhStatus, refresh, pageIndex, onSearch, dsKhuVucExtra, defaultStatuses, searchFromDateTodate } = props;

  const [searchStr, setSearchStr] = useState()
  const [openFilterNgay, setOpenFilterNgay] = useState(true)
  const [openLotrinhStatus, setLotrinhStatus] = useState(true)
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [selectedToMonth, setSelectedToMonth] = useState(new Date())
  const [selectedKVM, setSelectedKVM] = useState()
  const [selectedStatuses, setSelectedStatuses] = useState(defaultStatuses || [0, 1, 2, 3])
  const [searchByName, setSearchByName] = useState(false);
  const [selectedSubKV, setSelectedSubKV] = useState([])

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenFilter(open);
  };

  const handleSearchStrChanges = (str) => {
    setSearchStr(str);
    if (!str) {
      setSearchByName(!searchByName);
    }

  }

  useEffect(async () => {
    const fromDate1 = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1, 0, 0, 1).toISOString();
    let toDate1 = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0, 23, 59, 59).toISOString();
    if(searchFromDateTodate) {
      toDate1 = new Date(selectedToMonth.getFullYear(), selectedToMonth.getMonth() + 1, 0, 23, 59, 59).toISOString();
    }
    await onSearch(fromDate1, toDate1, pageIndex, selectedKVM, selectedStatuses, searchStr, selectedSubKV);

  }, [pageIndex, selectedMonth, cusId, refresh, selectedKVM, selectedStatuses, searchByName, selectedSubKV])

  const filter = () => (
    <Box
      role="presentation"
      style={{
        zIndex: 999999
      }}
    >
      <List>
        <ListItem key="filter-header">
          <ListItemText primary={"Filter"} />
          <IconButton
            onClick={evt => setOpenFilter(false)}
            className="close-icon-btn">x</IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key="ngay-filter" onClick={evt => setOpenFilterNgay(!openFilterNgay)}>
          <ListItemText primary={lang.generaltext.thangnam} />
          {openFilterNgay ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openFilterNgay} timeout="auto" unmountOnExit style={{
          padding: "0 20px"
        }}>
          {searchFromDateTodate && <ListItemText secondary = {lang.lotrinh.tuthang}/>}
          <MonthYearPicker value={selectedMonth} setValue={setSelectedMonth} />
          {searchFromDateTodate && <ListItemText secondary = {lang.lotrinh.denthang}/>}
          {searchFromDateTodate && <MonthYearPicker value={selectedToMonth} setValue={setSelectedToMonth} />}
        </Collapse>

      </List>
      <Divider />
      <KhuVucMasterCheckList onChange={kv => {
        setSelectedSubKV([])
        setSelectedKVM(kv)
      }} value={selectedKVM}
        selectedSubKV={selectedSubKV.map(k => k.id)} setSelectedSubKV={setSelectedSubKV}
        dsKhuVucExtra={dsKhuVucExtra || []}
      />
      {!disabledSearchLotrinhStatus && <Divider />}
      {!disabledSearchLotrinhStatus && <LotrinhStatuses onChange={setSelectedStatuses} checked={selectedStatuses} />}
    </Box>
  );



  return <Box className="flex-column">
    {!disabledSearchText && <TextField
      className="search-field"
      key="search_lotrinh_field_input"
      style={{
        maxWidth: 600
      }}
      value={searchStr}
      onInput={evt => handleSearchStrChanges(evt.target.value)}
      InputProps={{
        startAdornment: <InputAdornment position="start" ><IconButton disabled={!searchStr || searchStr.length < 3} onClick={evt => setSearchByName(!searchByName)} ><SearchIcon /></IconButton></InputAdornment>,
        endAdornment: <InputAdornment position="end"><IconButton onClick={evt => handleSearchStrChanges("")} className="close-icon-btn">x</IconButton></InputAdornment>
      }}
    />}
    <div className="flex-row" style={{
      justifyContent: "flex-end",
      alignItems: "center",
      color: lightGreen[500]
    }}>
      <IconButton onClick={toggleDrawer(!openFilter)}><FilterIcon style={{ color: lightGreen[500] }} /></IconButton>
      <span>Filter</span>
    </div>
    <Drawer
      className="search-filter"
      variant="temporary"
      anchor="right"
      open={openFilter}
      onClose={toggleDrawer(false)}
    >

      {filter()}
    </Drawer>
  </Box>
}