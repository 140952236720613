import { Box, Button, IconButton } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import DataTable from "../../Components/DataTable"
import RefreshIcon from '@mui/icons-material/RefreshOutlined';

import lang from "../../asset/Language.json"
import SearchFilter from "../../Components/SearchLotrinhBar/searchFilter"
import LotrinhService from "../../services/LotrinhService";
import { lightGreen } from "@mui/material/colors";
import { SimCardDownload } from "@mui/icons-material";


export default function Baocaohieuquaduadon(props) {

    const { cusId } = props;
    const pageSize = 15
    const [refresh, setRefresh] = useState(false);
    const [rows, setRows] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [showLoading, setShowLoading] = useState(false)
    const [exportExcel, setExportExcel] = useState()
    const [exportExcelSummary, setExportExcelSummary] = useState()
    const [data, setData] = useState({ result: [], summaryData: {} });
    const [totals, setTotals] = useState()

    const curSearchReq = useRef();
    const handleSearch = async (fromDate1, toDate1, pageIndex, khuvucMaster, selectedStatuses, saleName, selectedSubKV) => {
        const sv = new LotrinhService();

        curSearchReq.current = {
            fromDate: fromDate1,
            toDate: toDate1,
            kvm: khuvucMaster ? khuvucMaster.id : 0,
            statuses: selectedStatuses,
            saleName: saleName || "",
            subKV: selectedSubKV && selectedSubKV.length > 0 ? selectedSubKV.map(k => k.id) : undefined
        };
        const r = await sv.searchDetail(pageIndex + 1, pageSize, curSearchReq.current);

        if (r.error || !r.result) {
            return;
        }

        setRows(r.result.map(o => {
            const h = parseInt(o.thoigianinMinutes / 60);
            const m = o.thoigianinMinutes - parseInt(o.thoigianinMinutes / 60) * 60;

            const h1 = parseInt(o.overtimeinMinutes / 60);
            const m1 = o.overtimeinMinutes - parseInt(o.overtimeinMinutes / 60) * 60;
            return Object.assign(o, {
                thoigian: `${h < 10 ? "0" : ""}${h}:${m < 10 ? "0" : ""}${m}`,
                tangca: `${h1 < 10 ? "0" : ""}${h1}:${m1 < 10 ? "0" : ""}${m1}`,
                Ngay: new Date(`${o.ngay}Z`).toLocaleString("vi-vn", {
                    day: "2-digit",
                    month: '2-digit',
                    year: 'numeric'
                }),
                startTime: new Date(`${o.startTime}Z`).toLocaleString("vi-vn", {
                    hour: "2-digit",
                    minute: '2-digit',
                    hour12: false
                }),
                endTime: new Date(`${o.endTime}Z`).toLocaleString("vi-vn", {
                    hour: "2-digit",
                    minute: '2-digit',
                    hour12: false
                }),
                thang: new Date(`${o.ngay}Z`).toLocaleString("vi-vn", { month: "2-digit" })
            });
        }));
        setTotals(r.total);

    }

    const columns = [{
        field: ["saleName"],
        headerName: lang.lotrinh.nhanvienSale,
        width: 260
    },
    {
        field: "subKV",
        headerName: lang.lotrinh.khuvuc,
        width: 100
    },
    {
        field: "thang",
        headerName: lang.lotrinh.thang,
        width: 100
    },
    {
        field: "songaydangky",
        headerName: lang.lotrinh.songaydangky,
        width: 100
    },
    {
        field: "Ngay",
        headerName: lang.lotrinh.ngay,
        width: 100,
    },
    {
        field: "startTime",
        headerName: lang.lotrinh.starttime,
        width: 100,
    },
    {
        field: "endTime",
        headerName: lang.lotrinh.endTime,
        width: 100,
    },
    {
        field: "thoigian",
        headerName: lang.lotrinh.thoigian,
        width: 100,
    },
    {
        field: "tangca",
        headerName: lang.lotrinh.tangca,
        width: 100,
    },
    {
        field: "sokm",
        headerName: lang.lotrinh.sokm,
        width: 100
    },
    {
        field: "sokmvuot",
        headerName: lang.lotrinh.sokmvuot,
        width: 100
    }]

    useEffect(async () => {
        if (!exportExcel) {
            return;
        }
        setExportExcel(false);
        const req = curSearchReq.current;

        const sv = new LotrinhService();
        const todate = new Date(req.toDate);
        const month = todate.getMonth() + 1;
        const year = todate.getFullYear();
        await sv.Baocaohieuquaduadon(req, `Baohieuquaduadon_${req.kvm || ""}_T${month}.${year}.xlsx`);
    }, [exportExcel])

    useEffect(async () => {
        if (!exportExcelSummary) {
            return;
        }
        setExportExcelSummary(false);
        const req = curSearchReq.current;

        const sv = new LotrinhService();
        const todate = new Date(req.toDate);
        const month = todate.getMonth() + 1;
        const year = todate.getFullYear();
        await sv.BaocaohieuquaduadonSummary(req, `Baohieuquaduadon_summary_${req.kvm || ""}_T${month}.${year}.xlsx`);
    }, [exportExcelSummary])

    return <React.Fragment>
        {<Box
            className="main-content"

        >
            <div className="page-header">
                <div className="page-title"><span>{lang.lotrinh.baocaohieuquaduadontitle}</span></div>
                <IconButton onClick={evt => setExportExcel(true)} style={{
                    color: lightGreen[600],
                    backgroundColor: lightGreen[100],
                    marginRight: 10
                }}
                    title={lang.lotrinh.baocaohieuquaduadonReport}
                >
                    <SimCardDownload />
                </IconButton>
                <IconButton onClick={evt => setExportExcelSummary(true)} style={{
                    color: lightGreen[900],
                    backgroundColor: lightGreen[400],
                    marginRight: 10
                }}
                    title={lang.lotrinh.baocaohieuquaduadonsummaryReport}
                >
                    <SimCardDownload />
                </IconButton>
                <Button variant="text" className="btn-text" title="Refresh" onClick={evt => setRefresh(!refresh)}><RefreshIcon />Refresh</Button>
            </div>

            <SearchFilter cusId={cusId}
                onSearch={handleSearch}
                refresh={refresh}
                pageIndex={pageIndex}
                defaultStatuses={[2]}
                searchFromDateTodate={true}
                disabledSearchText={true}
            />
            <DataTable
                rows={rows}
                columns={columns}
                rowCount={totals}
                pageSize={pageSize}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={false}
                disableColumnMenu={true}
                pagination={true}
                paginationMode={'server'}
                loading={showLoading}
                onPageChange={page => setPageIndex(page)}
            />
        </Box>}

    </React.Fragment>
}